/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */
import React from 'react';
import NoSsr from "@material-ui/core/NoSsr";

export const wrapRootElement = ({ element }) => {
  return (
    <NoSsr>
      {element}
    </NoSsr>
  )
}

export const onRouteUpdate = () => {
  window.locations = window.locations || [document.referrer]
  locations.push(window.location.href)
  window.previousPath = locations[locations.length - 2]
}

